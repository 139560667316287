import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Text, Heading, Pressable, Skeleton, VStack, useTheme, Stack, Button, HStack } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';
import main from '../../Assets/Styles/main.json';
import AppContainer from '../Components/AppContainer';
import { useIsFocused } from '@react-navigation/native';
import APIAction from '../../Actions/APIAction';
import { v4 as uuidv4 } from 'uuid';
import Trans from '../Components/Trans';
import UserAction from '../../Actions/UserAction';
import Job from '../Jobs/Pending/Components/Job';
import JobView from '../Components/JobView';
import CalendarAction from "../../Actions/CalendarAction";
import Lottie from '../../Libs/Lottie/index';
import lottieEmptyCalendar from '../../Assets/Lottie/empty-calendar.json';
import lottiePencilCheckboxes from '../../Assets/Lottie/pencil-checkboxes.json';
import TimeClock from './Components/TimeClock';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const TrophyScreen = ({ navigation }) => {
    // Init
    const isFocused = useIsFocused();
    const { colors } = useTheme();

    //let [skillSuggestions, setSkillSuggestions] = useState(null);
    //let [suggestions, setSuggestions] = useState([]);
    let [loaded, setLoaded] = useState(false);
    let [jobsToApprove, setJobsToApprove] = useState([
        <Skeleton key={uuidv4()} h={'40'} marginBottom={'3'} />,
        <Skeleton key={uuidv4()} h={'40'} marginBottom={'3'} />,
    ]);
    let [jobsWithoutHours, setJobsWithoutHours] = useState([
        <Skeleton key={uuidv4()} h={'40'} marginBottom={'3'} />,
        <Skeleton key={uuidv4()} h={'40'} marginBottom={'3'} />,
    ]);
    let [recentJobs, setRecentJobs] = useState([
        <Skeleton key={uuidv4()} h={'40'} marginBottom={'3'} />,
        <Skeleton key={uuidv4()} h={'40'} marginBottom={'3'} />,
    ]);
    let [jobElements, setJobElements] = useState([
        <Skeleton key={uuidv4()} h={'40'} marginBottom={'3'} />,
        <Skeleton key={uuidv4()} h={'40'} marginBottom={'3'} />,
    ]);
    let [pastJobs, setPastJobs] = useState([
        <Skeleton key={uuidv4()} h={'40'} marginBottom={'3'} />,
        <Skeleton key={uuidv4()} h={'40'} marginBottom={'3'} />,
    ]);
    const calendarLoaded = useRef(false);

    const getJobsWithoutHours = async () => {
        // Init
        let jobElements = [];

        // Get jobs from api
        let jobs = await APIAction.request({
            method: 'get', url: '/api/get/past/employee_shifts', params: {
                hoursFilledIn: 0,
            },
        });

        if ('hydra:member' in jobs) {
            jobs = jobs['hydra:member'];
        }

        // Loop through jobs
        for (const key in jobs) {
            const job = jobs[key];

            // Add element to array
            jobElements.push(
                <JobView key={uuidv4()} job={job} action={'fillInHours'} />
            );
        }

        setJobsWithoutHours(jobElements);
    };


    /**
     * Get past jobs to upload costs
     */
    const getPastJobs = async () => {
        // Init
        let jobElements = [];

        // Get jobs from api
        let jobs = await APIAction.request({
            method: 'get', url: '/api/get/past/employee_shifts', params: {
                pastShifts: true,
            },
        });

        if ('hydra:member' in jobs) {
            jobs = jobs['hydra:member'];
        }

        // Loop through jobs
        for (const key in jobs) {
            const job = jobs[key];

            // Add element to array
            jobElements.push(
                <JobView key={uuidv4()} job={job} action={'fillInHours'} />
            );
        }

        setPastJobs(jobElements);
    };

    /**
     * Get jobs to approve
     *
     * @returns {Promise<void>}
     */
    const getJobsToApprove = async () => {
        // Get jobs
        let jobs = await APIAction.request({
            method: 'get', url: '/api/get/upcoming/employee_shifts', params: {
                approved: 0,
            },
        });

        if ('hydra:member' in jobs) {
            jobs = jobs['hydra:member'];
        }

        let approveElements = [];

        // Loop through jobs
        for (const key in jobs) {
            const job = jobs[key];

            // Add element to array
            approveElements.push(
                <JobView key={uuidv4()} job={job} action={'approve'} />
            );
        }

        setJobsToApprove(approveElements);
    };

    /**
     * Get recently added jobs
     *
     * @returns {Promise<void>}
     */
    const getRecentJobs = async () => {
        // Init
        let elements = [];

        // Get recent jobs
        let jobs = await APIAction.request({
            method: 'get', url: '/api/get/upcoming/employee_shifts', params: {
                sort: 'es.createdAt',
                direction: 'ASC',
                limit: 3,
            },
        });

        if ('hydra:member' in jobs) {
            jobs = jobs['hydra:member'];
        }

        // Loop through jobs
        for (const key in jobs) {
            const job = jobs[key];

            // Add element to array
            elements.push(
                <JobView key={uuidv4()} job={job} action={'preview'} />
            );
        }

        setRecentJobs(elements);
    };

    useEffect(() => {

        /**
         * Get recently upcoming jobs
         *
         * @returns {Promise<void>}
         **/
        const getUpcomingJobs = async () => {
            // Get jobs
            let jobs = await APIAction.request({ method: 'get', url: '/api/get/upcoming/employee_shifts' });

            if ('hydra:member' in jobs) {
                jobs = jobs['hydra:member'];
            }

            let elements = [];

            // Loop through jobs
            for (const key in jobs) {
                const job = jobs[key];

                // Add element to array
                elements.push(
                    <Stack
                        direction={{
                            md: 'row',
                            base: 'column'
                        }}
                    >
                        <Box style={{ marginBottom: 10, flexGrow: 1 }} key={job.id + '_jelement'}>
                            <Pressable
                                sytle={{ paddingLeft: 10 }}
                                onPress={() => {
                                    navigation.navigate('Preview', {
                                        id: job.id,
                                    });
                                }}
                            >
                                <Job style={{ marginBottom: 0 }} key={uuidv4()} job={job} />
                            </Pressable>
                        </Box>
                        <TimeClock job={job} onReload={() => getUpcomingJobs()} />
                    </Stack>
                );
            }

            setJobElements(elements);
        };

        const fetchData = async () => {
            if (isFocused) {

                // Get jobs
                await getJobsToApprove();
                await getJobsWithoutHours();
                await getRecentJobs();
                await getUpcomingJobs();
                await getPastJobs();

                if (!calendarLoaded.current) {
                    // Add jobs to calendar
                    calendarLoaded.current = true;
                    let dbUser = await UserAction.getUser();
                    await CalendarAction.addJobs(dbUser);
                }

                // Get language
                //let language = await TranslationAction.getSelectedLanguage();

                // Get current user
                //let user = await UserAction.getUser();

                // Get suggestions
                /*if (user) {
                    if (!suggestions.length && !loaded) {
                        setLoaded(true);
                        await getSuggestions();
                    }

                    if (suggestions.length) {
                        let skillSuggestionElement = [];
                        let skills = [];

                        // Loop through skills
                        for (const key in suggestions) {
                            let suggestion = suggestions[key];

                            // Get title
                            let title = suggestion.title.en;

                            if (language && suggestion.title.hasOwnProperty(language.code)) {
                                title = suggestion.title[language.code];
                            }

                            // Get category details (colors, etc.)
                            let parent = SkillsAction.getParent(suggestion);
                            let color = '';
                            let backgroundColor = '';

                            if (parent) {
                                color = parent.colorCode;
                                backgroundColor = parent.backgroundColorCode;
                            }

                            // Add element to array
                            skills.push(
                                <Box key={uuidv4()}>
                                    <Pressable
                                        style={[mainStyle.labelTag, {backgroundColor: backgroundColor ? backgroundColor : '#e9f5fb'}]}
                                        onPress={async () => {
                                            await SkillsAction.toggleSkill(suggestion.id, user.employee.id, 'add');
                                            await getSuggestions();
                                            GeneralAction.toast('success', <Trans>Skill added to your profile!</Trans>);
                                        }}
                                    >
                                        <Box>
                                            <Text style={{
                                                fontSize: 14,
                                                fontWeight: '500',
                                                color: color ? color : '#00aaff',
                                            }}>{title}</Text>
                                        </Box>
                                    </Pressable>
                                </Box>
                            );
                        }

                        // Add skill div
                        skillSuggestionElement.push(
                            <Box key={uuidv4()} style={mainStyle.boxItemVertical}>
                                <Box style={{width: '100%'}}>
                                    <Heading style={mainStyle.mediumTitle}><Trans>Suggestions</Trans></Heading>
                                    <Text style={mainStyle.infoText}><Trans>Based on your work history you can add the
                                        following skills to your profile. Click on a skill to add it to your
                                        profile.</Trans></Text>

                                    <Box style={{flexDirection: 'row', flexWrap: 'wrap', paddingTop: 10}}>
                                        {skills}
                                    </Box>
                                </Box>
                            </Box>
                        );

                        setSkillSuggestions(skillSuggestionElement);
                    }
                } */
            }
        };

        /**
         * Get skill suggestions
         *
         * @returns {Promise<void>}
         */
        /*let getSuggestions = async () => {
            let userSuggestions = await APIAction.request({method: 'get', url: '/api/get/skill_suggestions'});

            if ('hydra:member' in userSuggestions) {
                userSuggestions = userSuggestions['hydra:member'];
            }

            setSuggestions(userSuggestions);
        };*/


        fetchData();
    }, [isFocused, navigation]);

    return (
        <AppContainer>

            <VStack space={2}>
                <Button
                    backgroundColor={colors['primary'][600]}
                    style={{padding: 0}}
                    onPress={() => {
                        navigation.navigate('Availability');

                    }}
                >
                    <HStack space={1} alignItems={'center'}>
                    <Lottie source={lottiePencilCheckboxes} autoPlay={true} loop={true} speed={1} style={{ width: 50, height: 50 }} />
                        <Text color={'white'}><Trans>Keep your availabilities up to date here!</Trans></Text>
                    </HStack>
                </Button>
            </VStack>

            {/* Upcoming jobs */}
            {/*<Box style={mainStyle.pageTitleWrapper}>*/}
            {/*    <Box style={{ flexGrow: 1 }}>*/}
            {/*        <Heading style={mainStyle.pageTitle}><Trans>Upcoming jobs</Trans></Heading>*/}
            {/*    </Box>*/}
            {/*</Box>*/}

            {/* Default message */}
            {jobElements.length === 0 &&
                <VStack alignItems="center" style={{ marginTop: 60 }}>
                    <Text style={{ color: colors['primary']['600'], fontSize: 17 }}>
                        <Trans>No upcoming jobs.</Trans>
                    </Text>
                    <Lottie source={lottieEmptyCalendar} autoPlay={true} loop={true} speed={1} style={{ width: 230, height: 230 }} />
                </VStack>
            }

            {jobElements}

            {/* Default message */}
            {/*{jobsToApprove.length === 0 && jobsWithoutHours.length === 0 && recentJobs.length === 0 &&*/}
            {/*    <>*/}
            {/*        <Box key={uuidv4()} style={mainStyle.boxItemVertical}>*/}
            {/*            <Box style={{ width: '100%' }}>*/}
            {/*                <Heading style={mainStyle.mediumTitle}><Trans>No interactions required</Trans></Heading>*/}
            {/*                <Text style={mainStyle.infoText}>*/}
            {/*                    <Trans>At the moment there are no jobs that need your attention. New jobs that need your*/}
            {/*                        interaction will appear here.</Trans>*/}
            {/*                </Text>*/}
            {/*            </Box>*/}
            {/*        </Box>*/}
            {/*    </>*/}
            {/*}*/}

            {/* Jobs without hours */}
            {/*{*/}
            {/*    jobsWithoutHours.length > 0 &&*/}
            {/*    <>*/}
            {/*        <Box style={[mainStyle.pageTitleWrapper, { paddingTop: 20 }]}>*/}
            {/*            <Box style={{ flexGrow: 1 }}>*/}
            {/*                <Heading style={mainStyle.pageTitle}><Trans>Hours to approve</Trans></Heading>*/}
            {/*            </Box>*/}
            {/*        </Box>*/}
            {/*        {jobsWithoutHours}*/}
            {/*    </>*/}
            {/*}*/}

            {/* Jobs to approve */}
            {/*{*/}
            {/*    jobsToApprove.length > 0 &&*/}
            {/*    <>*/}
            {/*        <Box style={[mainStyle.pageTitleWrapper, { paddingTop: 20 }]}>*/}
            {/*            <Box style={{ flexGrow: 1 }}>*/}
            {/*                <Heading style={mainStyle.pageTitle}><Trans>Jobs to approve</Trans></Heading>*/}
            {/*            </Box>*/}
            {/*        </Box>*/}
            {/*        {jobsToApprove}*/}
            {/*    </>*/}
            {/*}*/}

            {/* Recently added jobs */}
            {
                jobsToApprove.length === 0 && recentJobs.length > 0 &&
                <>
                    <Box style={[mainStyle.pageTitleWrapper, { paddingTop: 20 }]}>
                        <Box style={{ flexGrow: 1 }}>
                            <Heading style={mainStyle.pageTitle}><Trans>New jobs</Trans></Heading>
                        </Box>
                    </Box>
                    {recentJobs}
                </>
            }

            {
                pastJobs.length > 0 && 
                <>
                    <Box style={[mainStyle.pageTitleWrapper, { paddingTop: 20 }]}>
                        <Box style={{ flexGrow: 1 }}>
                            <Heading style={mainStyle.pageTitle}><Trans>Past jobs</Trans></Heading>
                        </Box>
                    </Box>
                    {pastJobs}
                </> 
            }

            {/* <Box style={mainStyle.pageTitleWrapper}>
                <Box style={{flexGrow: 1}}>
                    <Heading style={mainStyle.pageTitle}>Wistedatje</Heading>
                </Box>
            </Box>
            <Box style={mainStyle.boxItemCenter}>
                <Box style={[mainStyle.trophieIconWrapper, {borderColor: 'transparent'}]}>
                    <Box style={[mainStyle.trophieIcon]}>
                        <Image size={60}
                               style={{resizeMode: 'contain'}}
                               source={require('../../Assets/Images/badge-icon-1.png')}
                        />
                    </Box>
                </Box>
                <Box style={mainStyle.trophieContentWrapper}>
                    <Heading style={mainStyle.mediumTitle}>Wist je dat je</Heading>
                    <Text style={mainStyle.infoText}>Reeds 200 uren werkte voor SilverTie en in totaal € 2715
                        verdiende.</Text>
                </Box>
                <Box style={{width: '8%', paddingStart: 10}}>
                    <Icon name={'angle-right'} size={20} style={{color: 'rgba(0,0,0,0.5)'}}/>
                </Box>
            </Box>
            <Box style={mainStyle.boxItemCenter}>
                <Box style={[mainStyle.trophieIconWrapper, {borderColor: 'rgba(219,220,222,0.6)', padding: 10}]}>
                    <Box style={[mainStyle.trophieIcon, {alignSelf: 'center', alignItems: 'center'}]}>
                        <Box style={{justifyContent: 'center', textAlign: 'center', alignItems: 'center'}}>
                            <Image size={30} mt={2}
                                   style={{resizeMode: 'contain', borderRadius: 30}}
                                   source={require('../../Assets/Images/user-2.png')}
                            />
                            <Text style={{textAlign: 'center', fontSize: 9, width: '100%'}}>Vincent</Text>
                        </Box>

                    </Box>
                </Box>
                <Box style={mainStyle.trophieContentWrapper}>
                    <Heading style={mainStyle.mediumTitle}>Skedle buddies</Heading>
                    <Text style={mainStyle.infoText}>Wist je dat je reeds 10x met Vincent samenwerkte. Jullie zijn nu
                        Skedle buddies </Text>
                </Box>
                <Box style={{width: '8%', paddingStart: 10}}>
                    <Icon name={'angle-right'} size={20} style={{color: 'rgba(0,0,0,0.5)'}}/>
                </Box>
            </Box>
            <Box style={mainStyle.boxItemVertical}>
                <Heading style={mainStyle.mediumTitle}>Your trophies</Heading>
                <Box style={{flexDirection: 'row', alignItems: 'center', width: '100%', minWidth: '100%'}}>
                    <Box style={mainStyle.trophieList}>
                        <Box style={[mainStyle.trophieListItem, {alignSelf: 'center', alignItems: 'center'}]}>

                            <Image size={60}
                                   style={{resizeMode: 'contain', width: '100%'}}
                                   source={require('../../Assets/Images/badge-icon-2.png')}
                            />
                        </Box>
                        <Box style={[mainStyle.trophieListItem, {alignSelf: 'center', alignItems: 'center'}]}>

                            <Image
                                style={{resizeMode: 'contain', width: '100%'}}
                                source={require('../../Assets/Images/badge-icon-3.png')}
                            />
                        </Box>
                        <Box style={[mainStyle.trophieListItem, {alignSelf: 'center', alignItems: 'center'}]}>

                            <Image size={60}
                                   style={{resizeMode: 'contain', width: '100%'}}
                                   source={require('../../Assets/Images/badge-icon-4.png')}
                            />
                        </Box>
                    </Box>
                    <Box style={[mainStyle.trophieNew, {backgroundColor: '#e8eefc'}]}>
                        <Box style={{paddingEnd: 10, width: '50%'}}>
                            <Box>
                                <Text style={{fontSize: 8, color: '#4d63cf', lineHeight: 11}}>New medal</Text>
                                <Text style={{
                                    fontSize: 12,
                                    lineHeight: 15,
                                    textTransform: 'uppercase',
                                    color: '#4d63cf',
                                    fontWeight: '700'
                                }}>Gold Cup</Text>
                                <Text style={{
                                    borderRadius: 6,
                                    textTransform: 'uppercase',
                                    fontSize: 7,
                                    color: '#ffffff',
                                    backgroundColor: '#ffb412',
                                    textAlign: 'center'
                                }}>unlocked</Text>
                            </Box>
                        </Box>
                        <Box style={{width: '50%', alignItems: 'center', paddingEnd: 10}}>
                            <Image
                                style={{resizeMode: 'contain'}}
                                source={require('../../Assets/Images/badge-icon-1.png')}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box> */}

            {/* {suggestions.length !== 0 && skillSuggestions}*/}
            
        </AppContainer>
    );
};

export default TrophyScreen;
